const Images = () => {

    const bgImage = require('../images/hero.jpeg');
    const bgBlank = require('../images/blank_bg.jpeg');
    const profileImage = require('../images/profile.jpg');
    const logo = require('../images/logo.png');
    const tmc1 = require('../images/tmc1.png');
    const tmc2 = require('../images/tmc2.png');
    const tmc3 = require('../images/tmc3.png');
    const tmc4 = require('../images/tmc4.png');
    const tmc5 = require('../images/tmc5.jpeg');
    const whatsapp = require('../images/whatsapp.png');
    const telegram = require('../images/telegram.png');
    const build_app_website_banner = require('../images/build_app_website_banner.png');
    const globeGif1 = require('../images/globe1.gif');
    const globeGif2 = require('../images/globe2.gif');

    return {bgImage: bgImage, profileImage: profileImage, bgBlank: bgBlank, logo, build_app_website_banner, 
        globeGif1,
        globeGif2,
        tmc1, tmc2, tmc3, tmc4, tmc5, whatsapp, telegram}
}

export default Images
