import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Home from "./components/landing/home";
import Profile from "./components/user/profile";
import UpdateProfile from "./components/user/updateProfile";
import ContentsShared from "./components/user/contentsShared";
import UserOverview from "./components/user/userOverview";
import ContentsOverview from "./components/content/contentsOverview";
import Admin from "./components/admin/admin";
import NewAdmin from "./components/admin/newAdmin";
import Announcements from "./components/admin/announcements";
import NewAnnouncement from "./components/admin/newAnnouncement";
import Influencers from "./components/admin/users";
import AppInfo from "./components/admin/appInfo";
import SuspendApp from "./components/admin/suspendApp";
import UpdatePassword from "./components/user/updatePassword";
import AllContents from "./components/content/allContents";
import CreateContent from "./components/content/createContent";
import NotSharedContents from "./components/content/notSharedContents";
import PendingContents from "./components/content/pendingContents";
import SharedContents from "./components/content/sharedContents";
import ResetPassword from "./components/auth/resetPassword";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import FAQ from "./components/landing/faq";
import About from "./components/landing/about";
import LogOut from "./components/auth/logout";
import MainLayout from "./components/layouts/mainLayout";
import AuthLayout from "./components/layouts/authLayout";
import LoginRedirect from "./components/auth/loginRedirect";
import { ToastContainer } from "react-toastify";
import ViewContent from "./components/content/viewContent";
import EditUser from "./components/admin/editUser";
import TopInfluencers from "./components/admin/topUsers";
import ContactUs from "./components/landing/contact";
import AdminLayout from "./components/layouts/adminLayout";
import PageNotFound from "./components/landing/pageNotFound";
import UnderMaintenance from "./components/landing/underMaintenance";
import RecentContentsForUsers from "./components/landing/recentContents";
import FilterContents from "./components/content/filterContents";
import FilterAllContents from "./components/content/filterAllContents";
import PurchaseCoupon from "./components/auth/purchaseCoupon";
import SelectCountry from "./components/auth/selectCountry";
import Referrals from "./components/user/referrals";
import BankAccounts from "./components/user/bankAccounts";
import Transactions from "./components/user/transactions";
import Coupons from "./components/vendor/coupons";
import UnusedCoupons from "./components/vendor/unusedCoupons";
import UsedCoupons from "./components/vendor/usedCoupons";
import SearchCoupons from "./components/vendor/searchCoupon";
import UserReferrals from "./components/admin/userReferrals";
import MainHomeLayout from "./components/layouts/mainHomeLayout";
import MainHome from "./components/home/mainHome";
import Terms from "./components/landing/terms";
import VerifyCouponWidget from "./components/auth/verifyCouponWidget";
import TopEarners from "./components/landing/topEarners";
import Withdraw from "./components/user/withdraw";
import PendingWithdrawals from "./components/admin/pendingWithdrawals";
import ViewProduct from "./components/products/viewProduct";
import AllProducts from "./components/products/allProducts";
import FilterProducts from "./components/products/filterProducts";
import FilterAllProducts from "./components/products/filterAllProducts";
import CreateProduct from "./components/products/createProduct";
import PendingProducts from "./components/products/pendingProducts";
import MarketPlace from "./components/home/marketPlace";
import AgentHome from "./components/home/agentHome";
import HowItWorks from "./components/landing/howItWorks";
import PackageLimitations from "./components/landing/packageLimitations";
import OurServices from "./components/landing/ourServices";

function App() {
  return (
<BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout/>}>
      <Route index element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/recent" element={<RecentContentsForUsers />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/services" element={<OurServices />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/package-limitations" element={<PackageLimitations />} />

      <Route path="/user/profile" element={<Profile />} />
      <Route path="/user/banks" element={<BankAccounts />} />
      <Route path="/user/withdraw" element={<Withdraw />} />
      <Route path="/user/referrals" element={<Referrals />} />
      <Route path="/user/transactions" element={<Transactions />} />
      <Route path="/user/profile/update" element={<UpdateProfile />} />
      <Route path="/user/profile/password/update" element={<UpdatePassword />} />
      <Route path="/user/contents/shared" element={<ContentsShared />} />
      <Route path="/user/profile/overview" element={<UserOverview />} />
      
      <Route path="/vendor/coupons" element={<Coupons />} />
      <Route path="/vendor/coupons/unused" element={<UnusedCoupons />} />
      <Route path="/vendor/coupons/used" element={<UsedCoupons />} />
      <Route path="/vendor/coupons/search" element={<SearchCoupons />} />

      <Route path="/content/:userId/:uniqueId/view" element={<ViewContent />} />
      <Route path="/content/:uniqueId/view" element={<ViewContent />} />
      <Route path="/content/all" element={<AllContents />} />
      <Route path="/content/filter" element={<FilterContents />} />
      <Route path="/content/filter/all" element={<FilterAllContents />} />
      <Route path="/content/new" element={<CreateContent />} />
      <Route path="/content/not-shared" element={<NotSharedContents />} />
      <Route path="/content/shared" element={<SharedContents />} />
      <Route path="/content/pending" element={<PendingContents />} />

      <Route path="/product/:userId/:uniqueId/view" element={<ViewProduct />} />
      <Route path="/product/:uniqueId/view" element={<ViewProduct />} />
      <Route path="/product/all" element={<AllProducts />} />
      <Route path="/product/filter" element={<FilterProducts />} />
      <Route path="/product/filter/all" element={<FilterAllProducts />} />
      <Route path="/product/new" element={<CreateProduct />} />
      <Route path="/product/pending" element={<PendingProducts />} />

      <Route path="*" element={<PageNotFound />} />
{/*</Route>

  <Route path="/admin" element={<AdminLayout/>}>*/}
      <Route path="/admin/list" element={<Admin />} />
      <Route path="/admin/new" element={<NewAdmin />} />
      <Route path="/admin/announcement/all" element={<Announcements />} />
      <Route path="/admin/announcement/new" element={<NewAnnouncement />} />
      <Route path="/admin/users" element={<Influencers />} />
      <Route path="/admin/users/top" element={<TopInfluencers />} />
      <Route path="/admin/user/:id/:username" element={<EditUser />} />
      <Route path="/admin/app/info" element={<AppInfo />} />
      <Route path="/admin/app/suspend" element={<SuspendApp />} />
      <Route path="/admin/overview" element={<ContentsOverview />} /> 
      <Route path="/admin/user/:username/referrals" element={<UserReferrals />} />
      <Route path="/admin/withdrawal/pending" element={<PendingWithdrawals />} />
 
      <Route path="*" element={<PageNotFound />} />    
      </Route>

      <Route path="/home" element={<MainHomeLayout/>}>
      <Route path="/home" element={<MainHome />} />
      <Route path="/home/contents" element={<RecentContentsForUsers />} />
      <Route path="/home/market-place" element={<MarketPlace />} />
      <Route path="/home/top-earners" element={<TopEarners />} />
      </Route>

      <Route path="/agent" element={<MainHomeLayout/>}>
      <Route path="/agent" element={<AgentHome />} />
      </Route>

      <Route path="/auth" element={<AuthLayout/>}>
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/login/redirect" element={<LoginRedirect />} />
      <Route path="/auth/register" element={<Register />} />
      <Route path="/auth/:referer/register" element={<Register />} />
      <Route path="/auth/country/select" element={<SelectCountry />} />
      <Route path="/auth/coupon" element={<PurchaseCoupon />} />
      <Route path="/auth/coupon/verify" element={<VerifyCouponWidget />} />
      <Route path="/auth/password/reset" element={<ResetPassword />} />
      <Route path="/auth/logout" element={<LogOut />} />
      <Route path="/auth/under-maintenance" element={<UnderMaintenance />} />
      <Route path="*" element={<PageNotFound />} />
      </Route>
   </Routes>
   <ToastContainer/>
        </BrowserRouter>
  )
}

export default App;
